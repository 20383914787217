import React from "react"
import { useState, useRef } from "react"
import ZimFrame from "components/ZimFrame/ZimFrame"

import { Col, Container, Row } from "react-bootstrap"
import Layout from "components/layout"
import SEO from "components/seo"

import ElementsContext from "src/context/ElementsContext"

const image = {
  url: "https://sortituploadstorage.blob.core.windows.net/test-image/X_Test.jpg",
  width: 416,
  height: 416,
}

const DemoPage = () => {
  const [recognisedElements, setRecognisedElements] = useState(//new Set(
    [
        {
            elementName: "DoorSectionDetection",
            elementCount: 5,
            boundingBoxes: [
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.32607317,
                            "y": 0.1632697
                        },
                        "width": 0.10823655,
                        "height": 0.09503803
                    },
                    "confidence": 0.9999994,
                    "index": 1
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.5142038,
                            "y": 0.48232365
                        },
                        "width": 0.09569025,
                        "height": 0.09628987
                    },
                    "confidence": 0.99995273,
                    "index": 2
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.63092,
                            "y": 0.1484214
                        },
                        "width": 0.102496386,
                        "height": 0.11696583
                    },
                    "confidence": 0.9999183,
                    "index": 3
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.18442768,
                            "y": 0.6528633
                        },
                        "width": 0.080126226,
                        "height": 0.10671973
                    },
                    "confidence": 0.9997586,
                    "index": 4
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.6292608,
                            "y": 0.45069546
                        },
                        "width": 0.09872675,
                        "height": 0.0966413
                    },
                    "confidence": 0.989058,
                    "index": 5
                }
            ]
        },
        {
            elementName: "WindowSectionDetection",
            elementCount: 7,
            boundingBoxes: [
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.7162636,
                            "y": 0.8051857
                        },
                        "width": 0.15574527,
                        "height": 0.05033636
                    },
                    "confidence": 0.9999989,
                    "index": 1
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.28156614,
                            "y": 0.80102485
                        },
                        "width": 0.09231979,
                        "height": 0.05247283
                    },
                    "confidence": 0.9999897,
                    "index": 2
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.10572899,
                            "y": 0.10956358
                        },
                        "width": 0.17633313,
                        "height": 0.057288073
                    },
                    "confidence": 0.99992335,
                    "index": 3
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.71018195,
                            "y": 0.10958646
                        },
                        "width": 0.17948425,
                        "height": 0.058202177
                    },
                    "confidence": 0.99990803,
                    "index": 4
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.4953666,
                            "y": 0.80190617
                        },
                        "width": 0.08927068,
                        "height": 0.052304268
                    },
                    "confidence": 0.99976605,
                    "index": 5
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.46929348,
                            "y": 0.10797383
                        },
                        "width": 0.09196377,
                        "height": 0.060275763
                    },
                    "confidence": 0.9993014,
                    "index": 6
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.089146696,
                            "y": 0.8074994
                        },
                        "width": 0.09869192,
                        "height": 0.04646635
                    },
                    "confidence": 0.999146,
                    "index": 7
                }
            ]
        },
        {
            elementName: "ToiletPlanDetection",
            elementCount: 2,
            boundingBoxes: [
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.28965253,
                            "y": 0.7239733
                        },
                        "width": 0.096080184,
                        "height": 0.100314856
                    },
                    "confidence": 0.99999654,
                    "index": 1
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.4289479,
                            "y": 0.586146
                        },
                        "width": 0.099169105,
                        "height": 0.0983088
                    },
                    "confidence": 0.9999942,
                    "index": 2
                }
            ]
        },
        {
            elementName: "BasinDetection",
            elementCount: 2,
            boundingBoxes: [
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.34480807,
                            "y": 0.6226101
                        },
                        "width": 0.06930846,
                        "height": 0.11637962
                    },
                    "confidence": 0.97339547,
                    "index": 1
                },
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.6148369,
                            "y": 0.6355386
                        },
                        "width": 0.067831635,
                        "height": 0.10894072
                    },
                    "confidence": 0.93662906,
                    "index": 2
                }
            ]
        },
        {
            elementName: "ShowerDetection",
            elementCount: 1,
            boundingBoxes: [
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.24859807,
                            "y": 0.5277605
                        },
                        "width": 0.11894202,
                        "height": 0.12557971
                    },
                    "confidence": 0.88461006,
                    "index": 1
                }
            ]
        },
        {
            elementName: "BathDetection",
            elementCount: 1,
            boundingBoxes: [
                {
                    "boxDimensions": {
                        "p1": {
                            "x": 0.40061176,
                            "y": 0.71454513
                        },
                        "width": 0.22428393,
                        "height": 0.11262798
                    },
                    "confidence": 0.92159975,
                    "index": 1
                }
            ]
        }
    ]
    //)
  );

  // Need to use forwardRef() if we want to pass to another child
  const [selectedBoxName, setSelectedBoxName] = useState("none");
  const selectedBoxRef = useRef("none");

  const handleSelection = (selectedBoxName) => {
    this.selectedBoxName = selectedBoxName;
    selectedBoxRef.current = selectedBoxName;
  }

  const addBox = (elementType) => {
    const elementBoxes = recognisedElements.find(element => element.elementName === elementType).boundingBoxes;
    const highestIndex = elementBoxes.reduce((acc, curr) => {
        return Math.max(acc, curr.index);
    }, 0);

    elementBoxes.push({
        "boxDimensions": {
            "p1": {
                "x": -1.0,
                "y": -1.0
            },
            "width": 0.1,
            "height": 0.1
        },
        "confidence": 1.0,
        "index": highestIndex + 1,
    })
    setRecognisedElements([...recognisedElements]);
  }

  const removeBox = (elementType, index) => {
    const recognisedElement = recognisedElements.find(element => element.elementName === elementType);
    const elementBoxes = recognisedElement.boundingBoxes;
    const elIndex = elementBoxes.findIndex(box => box.index === index);
    if (elIndex === -1) {
        return;
    }  
    
    elementBoxes.splice(elIndex, 1);
    recognisedElement.elementCount = elementBoxes.length;
    
    setRecognisedElements([...recognisedElements]);
  }

  const elementType = "ToiletPlanDetection";
  const index = 1;

  return (
    <div className="background-grey">
      <Layout pageInfo={{ pageName: "demo" }}>
        <SEO title="Demo" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="px-0" id="root">
                <div>
                <p style={{color: 'white'}}>{selectedBoxName}</p>
                </div>
                <div>
                    <button onClick={() => addBox(elementType)}>Add Box</button>
                    <button onClick={() => removeBox(elementType, index)}>Remove Box</button>
                </div>
                <ElementsContext.Provider value={{ imageUrl: image.url, imageWidth: image.width, imageHeight: image.height, elements: recognisedElements }}>
                    <ZimFrame image={image} detectedElements={recognisedElements} selectionHandler={handleSelection} />
                </ElementsContext.Provider>
            </Col>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export default DemoPage
